import React, { useState } from 'react'; 
import ReactPaginate from 'react-paginate';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const NewsCard = (props) => {
	return (
		<div style={{ padding: '20' }}>
			<a href={props.url}>
				{props.title} by {props.author}
			</a>
		</div>
	);
};

function App() {

    const [hits, setHits] = useState([]);
    const [pageCount, setPageCount] = useState(1); 
    const [isLoaded, setisLoaded] = useState(false);
    const [currentPage, setcurrentPage] = useState(1); 
    const [query, setQuery] = useState(''); 

    const URL = `https://licitaciones.onrender.com/lics?q=${query}&page=${currentPage}&size=100`;

    const handleFetch = () => {
		fetch(URL)
			.then(response => response.json())
			.then(body => {
				setHits([...body.items]);
                                setPageCount(body.pages); 
                                setisLoaded(true); 
			})
			.catch(error => console.error('Error', error));
	};

	const handlePageChange = (selectedObject) => {
		setcurrentPage(selectedObject.selected);
		handleFetch();
	};

return (
    <div>
         <label>Search</label>
        <input type="text" onChange={(event) => setQuery(event.target.value)} />
        <button onClick={handleFetch}>Get Data</button>

			{isLoaded ? (
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell align="right">nombre</TableCell>
            <TableCell align="right">organizacion</TableCell>
            <TableCell align="right">Producto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hits.map((row) => (
            <TableRow
              key={row.name}
			  
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell  align="left" sx={{width: '100px'}}>{row.numero}</TableCell>
              <TableCell align="left">{row.nombre}</TableCell>
              <TableCell align="left">{row.organismo}</TableCell>
              <TableCell align="left">{row.descripcion_producto}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
			) : (
				<div></div>
			)}    
			{isLoaded ? (
				<ReactPaginate
					pageCount={pageCount}
					pageRange={2}
					marginPagesDisplayed={2}
					onPageChange={handlePageChange}
					containerClassName={'container'}
					previousLinkClassName={'page'}
					breakClassName={'page'}
					nextLinkClassName={'page'}
					pageClassName={'page'}
					disabledClassNae={'disabled'}
					activeClassName={'active'}
				/>
			) : (
				<div>Nothing to display</div>
			)} 

    </div>
);
  
}

export default App;